import Analytics, { OptimisationInputEvent } from '@experian-uk/web-common-analytics';
import abTests from '../../constants/abTests';

const raiseWebTransformationEvent = ({ subscriptions, conductrics }) => {
  const family = subscriptions?.current?.family || 'unknown';
  const variant = conductrics.sels?.[abTests.TRANSFORMATION_WEB.apiCode];

  if (!variant) {
    return;
  }

  const event = OptimisationInputEvent.fromObject({
    testName: `${family}_${abTests.TRANSFORMATION_WEB.name}`,
    testArea: 'Freemium',
    testVariant: conductrics.sels?.[abTests.TRANSFORMATION_WEB.apiCode],
  });

  Analytics.publish(event);
};

export default raiseWebTransformationEvent;
