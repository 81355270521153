import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import { FooterOOS } from '@experian-uk/corvetteuk-common-ui';

import { getEnv } from '../components/Context/env';
import { getSplitStatus } from '../reducers/split';
import { getTheme } from '../helpers';
import { InSessionRoute, OutOfSessionRoute } from '../components/Routes';
import { SessionTimeoutWarning } from '../components';
import { splitsList } from '../constants';
import ErrorIndex from './error';
import Footer from '../components/Layout/Footer';
import isSignup from '../helpers/isSignup';
import LandingHeader from '../components/landing/Header';
import Loading from '../components/Layout/Loading';
import NavigationWrapper from '../components/Layout/NavigationWrapper/NavigationWrapper';
import OutdatedBrowser from '../components/OutdatedBrowser';
import scrollToTop from '../decorators/scrollToTop';
import sendLoginSuccess from '../actions/loginSuccess/send';

const { REACT_APP_EXPERIENCE_HOME_URL, REACT_APP_PROTECT_URL } = getEnv();

const HomePage = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './home'),
  loading: Loading,
  modules: ['home'],
});

const LandingPage = Loadable({
  loader: () => import(/* webpackChunkName: "landing" */ './landing'),
  loading: Loading,
  modules: ['landing'],
});

const LoginPage = Loadable({
  loader: () => import(/* webpackChunkName: "login" */ './acquisition'),
  loading: Loading,
  modules: ['login'],
});

const MonitoringPage = Loadable({
  loader: () => import(/* webpackChunkName: "monitoring" */ './monitoring'),
  loading: Loading,
  modules: ['monitoring'],
});

const FraudReport = Loadable({
  loader: () => import(/* webpackChunkName: "fraudReport" */ './report'),
  loading: Loading,
  modules: ['fraudReport'],
});

const AlertsPage = Loadable({
  loader: () => import(/* webpackChunkName: "alerts" */ './alerts'),
  loading: Loading,
  modules: ['alerts'],
});

const CreditLockPage = Loadable({
  loader: () => import(/* webpackChunkName: "creditLock" */ './creditLock'),
  loading: Loading,
  modules: ['creditLock'],
});

const ExperienceHomeRedirect = () => {
  window.location.href = REACT_APP_EXPERIENCE_HOME_URL;
  return null;
};

const ProtectRedirect = () => {
  window.location.href = REACT_APP_PROTECT_URL;
  return null;
};

const DefaultLayout = ({ creditLockEnabled, isCpp }) => (
  <React.Fragment>
    <OutdatedBrowser />
    <NavigationWrapper isCpp={isCpp} />
    <SessionTimeoutWarning />
    <Switch>
      <Route path="/report" component={FraudReport} />
      <Route path="/monitoring" component={MonitoringPage} />
      <Route path="/alerts" component={AlertsPage} />
      {creditLockEnabled && <Route path="/credit-lock" component={CreditLockPage} />}
      <Route exact path="/" component={HomePage} />
      <Redirect from="*" to="/" />
    </Switch>
    <Footer />
  </React.Fragment>
);

DefaultLayout.propTypes = {
  creditLockEnabled: PropTypes.bool,
  isCpp: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  creditLockEnabled: false,
  isCpp: false,
};

const LandingLayout = () => (
  <React.Fragment>
    <LandingHeader />
    <Switch>
      <Route exact path="/get-started/protection" component={LandingPage} />
    </Switch>
    <FooterOOS />
  </React.Fragment>
);

const theme = getTheme();

@scrollToTop
@withRouter
@connect(
  state => ({
    creditLockEnabled: getSplitStatus(state.split, [splitsList.creditLock])[splitsList.creditLock],
    subscriptionLoaded: !!state.subscriptions.current,
    productDetailsLoaded: !!state.productDetails.productIdentifier,
    isCpp: state.productDetails.isCpp,
    isSignup: isSignup(state),
    overallWebOptimisationSplitTreatment: state.split?.rawTreatments[splitsList.overallWebTransformation]?.treatment,
    protectSplitTreatment: state.split?.rawTreatments[splitsList.protectWebRollout]?.treatment,
  }),
  dispatch => bindActionCreators({ sendLoginSuccess }, dispatch)
)
export default class Routes extends React.Component {
  static displayName = 'Routes';

  static propTypes = {
    creditLockEnabled: PropTypes.bool,
    subscriptionLoaded: PropTypes.bool,
    sendLoginSuccess: PropTypes.func.isRequired,
    isSignup: PropTypes.bool,
    productDetailsLoaded: PropTypes.bool,
    isCpp: PropTypes.bool,
    overallWebOptimisationSplitTreatment: PropTypes.string,
    protectSplitTreatment: PropTypes.string,
  };

  static defaultProps = {
    creditLockEnabled: false,
    subscriptionLoaded: false,
    isSignup: false,
    productDetailsLoaded: false,
    isCpp: false,
    overallWebOptimisationSplitTreatment: null,
    protectSplitTreatment: null,
  };

  componentDidMount() {
    if (this.props.subscriptionLoaded && this.props.productDetailsLoaded) {
      this.props.sendLoginSuccess(this.props.isSignup);
    }
  }

  componentDidUpdate() {
    if (this.props.subscriptionLoaded && this.props.productDetailsLoaded) {
      this.props.sendLoginSuccess(this.props.isSignup);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/error" component={ErrorIndex} />
          <OutOfSessionRoute exact path="/acquisition" component={LoginPage} />
          <Route exact path="/get-started/protection" component={LandingLayout} />
          {this.props.overallWebOptimisationSplitTreatment === 'on' && (
            <Route exact path="/" component={ExperienceHomeRedirect} />
          )}
          {this.props.protectSplitTreatment === 'on' && <Route exact path="/" component={ProtectRedirect} />}
          <InSessionRoute
            component={() => (
              <DefaultLayout creditLockEnabled={this.props.creditLockEnabled} isCpp={this.props.isCpp} />
            )}
          />
        </Switch>
      </ThemeProvider>
    );
  }
}
