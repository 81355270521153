/* istanbul ignore file */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';

import addresses from './addresses';
import alerts from './alerts';
import app from './app';
import auth from './auth';
import billingsubscriptions from './billingsubscriptions';
import boost from './boost';
import brand from './brand';
import communicationProfile from './communicationProfile';
import conductrics from './conductrics';
import creditLock from './creditLock';
import ecd from './ecd';
import featureEligibility from './featureEligibility';
import loginSuccess from './loginSuccess';
import monitoring from './monitoring';
import offers from './offers';
import productDetails from './productDetails';
import reports from './reports';
import scores from './scores';
import sessionTimeout from './sessionTimeout';
import splashPages from './splashPages';
import split from './split';
import subscriptions from './subscriptions';
import ui from './ui';
import voucherValidationResult from './voucherValidationResult';

export default history =>
  combineReducers({
    addresses,
    alerts,
    app,
    auth,
    billingsubscriptions,
    boost,
    brand,
    communicationProfile,
    conductrics,
    creditLock,
    ecd,
    featureEligibility,
    form,
    loginSuccess,
    monitoring,
    offers,
    productDetails,
    reports,
    router: connectRouter(history),
    sessionTimeout,
    scores,
    splashPages,
    split,
    subscriptions,
    ui,
    voucherValidationResult,
  });
