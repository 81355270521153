import fetch from '../fetch';
import { abTests } from '../../constants';
import { getCookieValue } from '../../helpers';

export const FETCH_SELECTIONS = 'FETCH_SELECTIONS';
export const SELECTIONS_FETCHED = 'SELECTIONS_FETCHED';
export const SELECTIONS_FAILED = 'SELECTIONS_FAILED';
export const SELECTIONS_UNAVAILABLE = 'SELECTIONS_UNAVAILABLE';

const fetchTargetingVariants = async (dispatch, parameters) =>
  dispatch(
    fetch('/targeting', {
      method: 'POST',
      body: {
        application: 'credit_expert',
        testIds: Object.values(parameters.abTests).map(test => test.apiCode),
        parameters: {
          inputs: parameters.inputs,
          traits: parameters.traits,
        },
      },
    })
  );

const getSelections = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_SELECTIONS });

  const state = getState();
  const fetched = {};

  const privacyCookies = getCookieValue('EXPERIAN_ENSIGHTEN_PRIVACY_Performance_and_Analytics') === '1';
  if (!privacyCookies) {
    fetched.type = SELECTIONS_UNAVAILABLE;
    return dispatch(fetched);
  }

  const parameters = {
    abTests,
    traits: {
      family: state.subscriptions?.current?.family,
    },
    inputs: {
      customerNumber: state.auth?.currentUser?.customerNumber,
    },
  };

  try {
    const targetingData = await fetchTargetingVariants(dispatch, parameters);
    fetched.payload = {
      data: targetingData
        ?.find(entry => entry.context === 'Targeting')
        ?.data?.reduce((acc, curr) => ({ ...acc, [curr.variantId]: curr.variantValue }), {}),
    };
    fetched.type = SELECTIONS_FETCHED;
  } catch (error) {
    fetched.type = SELECTIONS_FAILED;
  }

  return dispatch(fetched);
};

export default getSelections;
