import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AnimatedLogo } from '@experian-uk/corvetteuk-common-ui';

import getCustomer from '../../actions/auth/getCustomer';
import getSubscription from '../../actions/subscriptions/getSubscription';
import getProductDetails from '../../actions/product/getDetails';
import getBoostStatus from '../../actions/boost/getStatus';
import getSelections from '../../actions/conductrics/getSelections';
import { withEnv, envPropTypes } from '../Context/env';
import { FullPageContainer } from '../../components/Container';

@withRouter
@withEnv
@connect(
  state => ({
    authenticated: state.auth.authenticated,
    fetching: state.auth.fetching,
  }),
  dispatch =>
    bindActionCreators(
      {
        getBoostStatus,
        getCustomer,
        getSubscription,
        getProductDetails,
        getSelections,
      },
      dispatch
    )
)
export default class InSessionRoute extends React.Component {
  static propTypes = {
    env: envPropTypes.isRequired,
    getBoostStatus: PropTypes.func.isRequired,
    getCustomer: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    fetching: PropTypes.bool,
    getSubscription: PropTypes.func.isRequired,
    getProductDetails: PropTypes.func.isRequired,
    getSelections: PropTypes.func.isRequired,
  };

  static defaultProps = {
    authenticated: false,
    fetching: true,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    if (this.props.authenticated) {
      Promise.all([
        this.props.getBoostStatus(),
        this.props.getCustomer(),
        this.props.getSubscription(),
        this.props.getProductDetails(),
      ])
        .then(() => this.props.getSelections())
        .finally(() => {
          this.setState({ loading: false });
        });
    } else if (!this.props.fetching) {
      window.location = this.props.env.REACT_APP_AUTH_URL;
    }
  }

  render() {
    return this.state.loading ? (
      <FullPageContainer>
        <AnimatedLogo />
      </FullPageContainer>
    ) : (
      <Route {...this.props} />
    );
  }
}
