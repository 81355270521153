import {
  FETCH_SELECTIONS,
  SELECTIONS_FETCHED,
  SELECTIONS_UNAVAILABLE,
  SELECTIONS_FAILED,
} from '../actions/conductrics/getSelections';

const defaultState = {
  error: null,
  goalStatus: {},
  fetching: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case FETCH_SELECTIONS:
      return {
        ...state,
        fetching: true,
      };

    case SELECTIONS_FETCHED: {
      const selections = {
        ...state.sels,
        ...action.payload.data,
      };

      return {
        ...state,
        fetching: false,
        error: null,
        sels: selections,
      };
    }

    case SELECTIONS_UNAVAILABLE:
      return {
        ...state,
        fetching: false,
        error: null,
        sels: {},
      };

    case SELECTIONS_FAILED:
      return {
        ...state,
        fetching: false,
        error: true,
        sels: {},
      };

    default:
      return state;
  }
};
