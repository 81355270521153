import styled from 'styled-components';
import { Typography, Footer } from '@experian-uk/corvetteuk-common-ui';

export const SmallText = styled(Typography.Copy.SmallText)`
  display: block;
  margin: 20px 0;
  font-weight: 300;
  font-size: 0.875rem;
  color: ${props => props.theme.colors.experianDarkGrey};

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: 0.875rem;
  `
    )};
`;

export const StyledFooter = styled(Footer)`
  font-size: 0.875rem;
  padding-bottom: 4.5rem;

  ${({ theme }) =>
    theme.media(
      '10',
      `
    font-size: 0.875rem;
  `
    )};

  ${({ theme }) =>
    theme.media(
      '30',
      `
    max-width: ${theme.base.largeContainerWidth};
    margin: 0 auto;
  `
    )}
`;
