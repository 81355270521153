import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { setAutomationElement, HeadingWithEmphasis } from '@experian-uk/corvetteuk-common-ui';

import {
  Benefit,
  BenefitsList,
  ButtonContainer,
  Container,
  Content,
  ExpiryDate,
  Header,
  HeaderCopy,
  HeaderDetails,
  HeaderIntro,
  HeaderProduct,
  Description,
  ViewSubscriptionButton,
} from './index.styles';
import { EnvConsumer } from '../Context/env';
import dateFormat from '../../constants/dateFormat';
import { products } from '../../constants';

const SubscriptionInfo = ({
  benefits,
  defaultFamily,
  description,
  expiryDate,
  subscriptionFamily,
  supportedFamilyNames,
  isBreach,
}) => {
  const familyName = supportedFamilyNames.includes(subscriptionFamily) ? subscriptionFamily : defaultFamily;
  const shouldShowExpiryDate = expiryDate && isBreach && dayjs(expiryDate, 'YYYY-MM-DD').isValid();
  const benefitsToShow = isBreach ? benefits.breachBenefits : benefits.identityBenefits;
  return (
    <Container {...setAutomationElement('subInfo')}>
      <Header>
        <HeaderCopy>
          <HeaderIntro>You are subscribed to</HeaderIntro>
          <HeaderProduct {...setAutomationElement('subInfoHeader')}>
            <HeadingWithEmphasis heading={familyName} />
          </HeaderProduct>
        </HeaderCopy>
        {(description || expiryDate) && (
          <HeaderDetails>
            {description && <Description {...setAutomationElement('subInfoDescription')}>{description}</Description>}
            {shouldShowExpiryDate && (
              <ExpiryDate {...setAutomationElement('subInfoExpiryDate')}>
                Ends {dayjs(expiryDate).format(dateFormat.dayMonthYearFormat)}
              </ExpiryDate>
            )}
          </HeaderDetails>
        )}
      </Header>
      <Content>
        <BenefitsList {...setAutomationElement('benefitsList')}>
          {benefitsToShow.map(benefit => (
            <Benefit key={benefit}>
              <span>{benefit}</span>
            </Benefit>
          ))}
        </BenefitsList>
        <ButtonContainer>
          <EnvConsumer>
            {context => (
              <ViewSubscriptionButton
                {...setAutomationElement('mySubscriptionsCTA')}
                href={context.REACT_APP_PRODMOVE_URL}
                label="View my subscription"
                type="secondary"
              />
            )}
          </EnvConsumer>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

SubscriptionInfo.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.string),
  defaultFamily: PropTypes.string,
  description: PropTypes.string,
  expiryDate: PropTypes.string,
  subscriptionFamily: PropTypes.string,
  supportedFamilyNames: PropTypes.arrayOf(PropTypes.string),
  isBreach: PropTypes.bool,
};

SubscriptionInfo.defaultProps = {
  benefits: {},
  defaultFamily: products.identity,
  description: '',
  expiryDate: '',
  subscriptionFamily: '',
  supportedFamilyNames: [products.identity, products.creditExpert],
  isBreach: false,
};

export default SubscriptionInfo;
